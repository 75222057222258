import React from 'react'
import AddProduct from '../../Component/AddProducts/AddProduct'

function Admin() {
  return (
    <><div>Admin</div>
    <AddProduct/>
    </>

  )
}

export default Admin